import React from 'react';
import './index.css';

export default class Nav extends React.Component {

    render() {
        return (
            // <div className="">
                <nav className="
                                relative
                                w-full
                                flex flex-wrap
                                justify-center
                                h-20
                                p-1
                                ">
                    <div className="">
                        <h4 className="text-[64px] font-damion text-gray1">Drem</h4>
                    </div>
                </nav>
            // </div>

        )
    }
}